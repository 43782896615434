
import "./template.css";

function TitleBlock(props) {
    let text = <div className="page-title-text">
        <h1>{props.title}</h1>
        <h2>{props.subtitle}</h2>
    </div>;
    return <div className="page-title">
        <div style={{border: "2px dotted green"}}>{text}</div>
        <img className="page-image" src={props.imgSrc} />
        <div className="page-title-box">
            {text}
        </div>
    </div>
}

export default TitleBlock;
