
import TitleBlock from "./titleBlock";
import {DescriptionBlock} from "./descriptionBlock";
import {NewsPreview} from "./newsPreview";
import genericBackgroundImgSrc from "./images/generic-background.jpg";
import companyImgSrc from "./images/company.jpeg";
import author from "./images/author.png";
import {URL} from "./config";

function subscribe() {
    let email = document.getElementById("emailInput").value.trim();
    document.getElementById("emailInput").value = "";
    //check if email is valid
    if(email.indexOf(" ") !== -1) {
        alert(email + " is not a valid email address (includes space)");
        return;
    }
    let i = email.indexOf("@");
    if(i === -1) {
        alert(email + " is not a valid email address (no at)");
        return;
    }
    let j = email.indexOf(".", i);
    if(j === -1 || email.indexOf(".", j + 1) !== -1) {
        alert(email + " is not a valid email address (no dot)");
        return;
    }
    fetch(URL + "/subscribe", {
        method: "POST",
        body: JSON.stringify({
            email: email,
        }),
        headers: {
            "Content-type": "application/json",
        }
    }).then((response) => {
        alert("Successfully subscribed with email address: " + email);
    }).catch((err) => {
        alert("Failed to connect to server, please try again later.");
    });
}

function Home(props) {
    return <div>
        <TitleBlock
            title="Sentient AI"
            subtitle="Harnessing the Power of Evolution to Create AI Solutions"
            imgSrc={genericBackgroundImgSrc}
        />

        <div className="section">
            <div className="container">
                <DescriptionBlock
                    title="Our Company"
                    subtitle="Artisanal Neural Networks Freshly Made"
                    description="At Sentient, we leverage one of the most untapped methodologies for optimizing AI -- Evolution. Through our use of evoltuionary
                    strategies, we create neural networks to solve your pressing needs."
                />
                <img className="descriptive-image" src={companyImgSrc} />
            </div>
        </div>

        <div className="section" style={{backgroundColor:"#f4f7f8"}}>
            <div className="container">
                <img className="descriptive-image" src={author} />
                <DescriptionBlock
                    title="Meet the dev"
                    subtitle="Ralph Wang, web and AI contractor"
                    description="Change this section to 'meet the CEO, Dawnis Chow'"
                />
            </div>
        </div>

        <div className="section">
            <h1>Latest News and Blog</h1>
            <div className="container">
                <NewsPreview />
            </div>
        </div>

        <div className="section" style={{
            backgroundColor: "#f4f5f8",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}>
            <h1> Sign up to receive updates</h1>
            <input type="text" id="emailInput" placeholder="Enter email here" />
            <button className="subscribe-button" onClick={subscribe}>Subscribe!</button>
        </div>
    </div>
}

export default Home;
