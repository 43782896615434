import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import React, {useState, useEffect} from 'react';
import api from './api'

import Header from "./frontend/header.js";
import Footer from "./frontend/footer.js";
import Home from './frontend/homePage';
import About from './frontend/aboutPage';
import Contact from './frontend/contactPage';
import News from './frontend/newsMainPage';
import "./frontend/template.css";
import "./frontend/contact.css";

const App = () => {
    return <div>
        <Header />
        
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/news" element={<News />} />
        </Routes>

        <Footer />
    </div>
};

export default App;