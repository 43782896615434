
import React, {useState, useEffect} from "react";
import defaultThumbnail from "./images/generic-news.jpg";
import "./template.css";
import {URL} from "./config.js";

function ArticlePreview(props) {
    const [blog_data, setData] = useState({
        blog_id: props.blog_id,
        title: "",
        author: "",
        thumbnail: defaultThumbnail,
        date: "",
    })

    useEffect(() => {
        let url = URL + "/blogcontent?blog_id=" + props.blog_id;
        fetch(url).then((response) => {
            return response.json();
        }).then((data) => {
            setData({
                ...blog_data,
                ...data,
                blog_id: props.blog_id,
                thumbnail: data.thumbnail ? URL + "/images/" + data.thumbnail 
                  : blog_data.thumbnail,
            });
        }).catch((err) => {
            console.log("Fetch error :-S", err);
        });
    }, [props.blog_id]);

    return <a className="article-preview" 
          href={URL + "/news?blog_id=" + blog_data.blog_id}>
            <img className="thumbnail" src={blog_data.thumbnail} />
            <div className="description-box">
                <h2>{blog_data.title}</h2>
                <p>By {blog_data.author}</p>
                <p>{blog_data.date}</p>
            </div>
        </a>;
}

class NewsPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blog_ids: ["unknown"],
        }

        let targetObject = this;
        fetch(URL + "/allnews").then((response) => {
            return response.json();
        }).then((data) => {
            targetObject.setState({
                blog_ids: data
            });
        }).catch((err) => {
            console.log(err);
        });
    }

    render() {
        let content = [];
        for(let i = 0; i < 3 && i < this.state.blog_ids.length; i++) {
            content.push(<ArticlePreview blog_id={this.state.blog_ids[i]} />);
        }
        return <div className="preview-container">
            {content}
        </div>
    
    }
}

export {NewsPreview}