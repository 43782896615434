
import TitleBlock from "./titleBlock.js";
import genericBackgroundImgSrc from "./images/generic-background.jpg";
import "./template.css";
import "./contact.css";
import {URL} from "./config.js";

function sendMessage() {
    let name = document.getElementById("name").value;
    let email = document.getElementById("email").value;
    let subject = document.getElementById("subject").value;
    let message = document.getElementById("message").value;
    document.getElementById("name").value = "";
    document.getElementById("email").value = "";
    document.getElementById("subject").value = "";
    document.getElementById("message").value = "";

    fetch(URL + "/message", {
        method: "POST",
        body: JSON.stringify({
            name: name,
            email: email,
            subject: subject,
            message: message,
        }),
        headers: {
            "Content-type": "application/json",
        }
    }).then((response) => {
        alert("Message sent!");
    }).catch((err) => {
        alert("Failed to connect, please try again later.");
    });
}

function Contact(props) {
    return <div>
        <TitleBlock
            title="Contact Us"
            subtitle=""
            imgSrc={genericBackgroundImgSrc}
        />

        <div className="content-body">
            <h2 style={{textAlign: "center"}}>Send us a message</h2>
            <div className="message-input-container">
                <input type="text" id="name" placeholder="Name" />
                <input type="text" id="email" placeholder="Email" />
                <input type="text" id="subject" placeholder="Subject" />
                <textarea type="text" id="message" placeholder="Message" />
                <button onClick={sendMessage}>Send!</button>
            </div>
        </div>
    </div>
}

export default Contact;