import logoImgSrc from "./images/logo.png";
import "./footer.css";
import "./template.css";
import api from '../api'
import fbIconSrc from "./images/facebook.png";
import instaIconSrc from "./images/instagram.png";
import discordIconSrc from "./images/discord.png";
import {NavLink} from "react-router-dom";
import React from "react";

const subscribe = async () => {
    let email = document.getElementById("emailInputFooter").value.trim();
    document.getElementById("emailInputFooter").value = "";
    //check if email is valid
    if(email.indexOf(" ") !== -1) {
        alert(email + " is not a valid email address (includes space)");
        return;
    }
    let i = email.indexOf("@");
    if(i === -1) {
        alert(email + " is not a valid email address (no at)");
        return;
    }
    let j = email.indexOf(".", i);
    if(j === -1 || email.indexOf(".", j + 1) !== -1) {
        alert(email + " is not a valid email address (no dot)");
        return;
    }

    await api.post('/subscribe', email);
}

function EmailInputForm(props) {
    return <div>
        <input type="text" id="emailInputFooter" placeholder="Enter email here"/>
        <div className="subscribe-button-footer" onClick={subscribe}>SUBSCRIBE</div>
    </div>
}

function Footer(props) {
    return <div className="footer">
        <div className="container">
            <div className="footerBlock">
                <img className="logoImgFooter" src={logoImgSrc} />
                <p>Some descriptive text here</p>
                <div className="icon-box-footer">
                    <div className="icon-spacer"></div>
                    <img className="icon-footer" src={fbIconSrc} />
                    <div className="icon-spacer"></div>
                    <img className="icon-footer" src={instaIconSrc} />
                    <div className="icon-spacer"></div>
                    <img className="icon-footer" src={discordIconSrc} />
                    <div className="icon-spacer"></div>
                </div>
            </div>
            <div className="footerBlock">
                <h2>Quick links</h2>
                <p className="footerText"><NavLink to="/">Home</NavLink></p>
                <p className="footerText"><NavLink to="/news">News & Blog</NavLink></p>
                <p className="footerText"><NavLink to="/about">About</NavLink></p>
                <p className="footerText"><NavLink to="/contact">Contact</NavLink></p>
            </div>
            <div className="footerBlock">
                <h2>Newsletter</h2>
                <p>Sign up to receive updates regarding our product!</p>
                <EmailInputForm />
            </div>
        </div>
    </div>
}

export default Footer;
