
import ReactDOM from "react-dom/client";

function About(props) {
    return <div>
        <div style={{width: "100%", height: "50dvh", fontSize: "50px", textAlign: "center"}}>About Us</div>
    </div>
}

export default About;
