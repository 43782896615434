
function DescriptionBlock(props) {
    return <div className="description-block">
        <h2>{props.title}</h2>
        <h4>{props.subtitle}</h4>
        <p>{props.description}</p>
    </div>
}

export {DescriptionBlock};
