import React, { useEffect } from 'react';
import "./header.css";
import "./template.css";
import logoImgSrc from "./images/logo.png";
import fbIconSrc from "./images/facebook.png";
import instaIconSrc from "./images/instagram.png";
import discordIconSrc from "./images/discord.png";

import { NavLink } from "react-router-dom";

const Header = () => {
    useEffect(() => {
        document.title = 'SentientAI';
    }, []);
    return <div>
        <div className="logoRow container">
            <img className="logo-header" src={logoImgSrc} />
            <div style={{margin: "0 auto"}}></div>
            <div style={{display: "flex"}}>
                <img className="icon-header" src={fbIconSrc} />
                <img className="icon-header" src={instaIconSrc} />
                <img className="icon-header" src={discordIconSrc} />
            </div>
        </div>
        <nav>
            <div className="container">
                <div className="menuItem"><NavLink to="/">Home</NavLink></div>
                <div className="menuItem"><NavLink to="/news">News & Blog</NavLink></div>
                <div className="menuItem"><NavLink to="/about">About</NavLink></div>
                <div className="menuItem"><NavLink to="/contact">Contact</NavLink></div>
            </div>
        </nav>
    </div>
}

export default Header
