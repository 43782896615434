import React from "react";
import genericBackgroundImgSrc from "./images/generic-background.jpg"
import {NewsPreview} from "./newsPreview";
import TitleBlock from "./titleBlock";

function News(props) {
    return <div>
        <TitleBlock
            title="News and Blog"
            subtitle="Read the latest stories here"
            imgSrc={genericBackgroundImgSrc}
        />

        <NewsPreview />
    </div>
}

export default News;
